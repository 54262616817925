import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/Button';

const SubmitButton = ({ label, onClick, disabled }) => (
  <Button label={label} variant="primary" onClick={onClick} disabled={disabled} />
);

SubmitButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

SubmitButton.defaultProps = {
  label: 'Submit Comment'
};

export default SubmitButton;
