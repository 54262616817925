/* eslint-disable camelcase */
import React from 'react';
import { PropTypes } from 'prop-types';
import analytics from 'lib/analytics';
import Text from 'components/Text';
import Anchor from 'components/Anchor';
import { EvaluationPropType, AuthPropType } from 'domains/Comments/proptypes';
import CollapsibleContent from 'domains/Comments/components/CollapsibleContent';
import EvaluationStarRating from '../EvaluationStarRating';
import EvaluationAvatarByline from '../EvaluationAvatarByline';
import EvaluationDataContainer from '../EvaluationDataContainer';
import EditButton from '../EditButton';
import ReplyContainer from '../ReplyContainer';
import MarkEvaluationAsHelpfulContainer from '../../../containers/MarkAsHelpfulContainer/MarkEvaluationAsHelpfulContainer';
import {
  ALL_STUDENT_FACING_RESOURCE_OPTIONS,
  SATISFACTION_RATING,
  TRACK_EDIT_EVENT,
  SHOW_LINE_HEIGHT
} from '../constants';
import './EvaluationDisplay.scss';

const EvaluationDisplay = ({
  resourceSellerId,
  showProductLink,
  evaluation: {
    id,
    resourceId,
    signals,
    updatedAt,
    user,
    helpfulCount,
    hasUserMarkedHelpful,
    signalsDataEducationStandards,
    sellerReply,
    resource
  },
  auth,
  isMobile,
  isLegacyGrid
}) => {
  const configureStarRating = (starSignalName) => ({
    rating: SATISFACTION_RATING[starSignalName].value,
    text: SATISFACTION_RATING[starSignalName].label
  });

  const satisfiedRating =
    signals.overall_qual_how_satisfied_stars ||
    null ||
    signals.overall_qual_how_satisfied_stars_smileys ||
    null ||
    signals.overall_qual_how_satisfied ||
    null;

  const isReviewer = auth && user && auth.id === user.id;

  const hasGradesSignal =
    signals.which_grade_used_with && !signals.which_grade_used_with.includes('none');

  const hasEngagementSignal =
    signals.this_resource_was_engaging_my_students &&
    signals.this_resource_was_engaging_my_students !== 'none';

  const hasStandardsSignal =
    signals.standards_alignment_score &&
    signalsDataEducationStandards &&
    signalsDataEducationStandards.length > 0;

  // if this is true then EvaluationDataContainer will not render
  const notStudentFacingAndNoStandards =
    signals.how_was_resource_used &&
    !ALL_STUDENT_FACING_RESOURCE_OPTIONS.includes(signals.how_was_resource_used) &&
    !hasStandardsSignal;

  const hasDataSignals =
    (hasGradesSignal || hasEngagementSignal || hasStandardsSignal) &&
    !notStudentFacingAndNoStandards;

  return (
    <div className="EvaluationDisplay">
      {showProductLink && (
        <div className="EvaluationDisplay__link">
          <Anchor color="green" forceReload to={resource.url} data-testid="EvaluationDisplay--link">
            {resource.name}
          </Anchor>
        </div>
      )}
      <EvaluationAvatarByline user={user} date={updatedAt} isMobile={isMobile} />
      {satisfiedRating && (
        <EvaluationStarRating {...configureStarRating(satisfiedRating)}>
          <Text color="textPrimary" variant="body" strong>
            {configureStarRating(satisfiedRating).text}
          </Text>
        </EvaluationStarRating>
      )}
      {signals.buyer_experience && (
        <div className="EvaluationDisplay__content">
          <CollapsibleContent
            collapsedContentHeight={SHOW_LINE_HEIGHT}
            hideContentLabel="Show less"
            showContentLabel="Show more"
            showIcon
          >
            {signals.buyer_experience}
          </CollapsibleContent>
        </div>
      )}
      {hasDataSignals && (
        <EvaluationDataContainer
          specialEdOptions={signals.special_ed_students || null}
          gradesUsedWith={signals.which_grade_used_with || null}
          standardsAlignmentScore={signals.standards_alignment_score || null}
          studentEngagement={signals.this_resource_was_engaging_my_students || null}
          standardsTaught={signalsDataEducationStandards || null}
          howWasResourceUsed={signals.how_was_resource_used || null}
          isLegacyGrid={isLegacyGrid}
        />
      )}
      <div className="EvaluationDisplay__actions">
        <MarkEvaluationAsHelpfulContainer
          helpfulCount={helpfulCount}
          hasCurrentUserMarkedHelpful={hasUserMarkedHelpful}
          isLoggedIn={!!(auth && auth.id)}
          evaluationId={id}
          canMarkAsHelpful={!isReviewer}
        />
        {isReviewer ? (
          <EditButton
            resourceId={resourceId}
            onClick={() => analytics.trackEvent(TRACK_EDIT_EVENT)}
          />
        ) : null}
        <ReplyContainer
          resourceSellerId={resourceSellerId}
          evaluationId={id}
          auth={auth}
          sellerReply={sellerReply}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
};

EvaluationDisplay.propTypes = {
  evaluation: EvaluationPropType,
  auth: AuthPropType,
  resourceSellerId: PropTypes.number.isRequired,
  changeMode: PropTypes.func.isRequired,
  showProductLink: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLegacyGrid: PropTypes.bool
};

EvaluationDisplay.defaultProps = {
  isLegacyGrid: false,
  showProductLink: false
};

export default EvaluationDisplay;
