export default {
  PRODUCT: 'Product',
  COMMENT: 'Comment',
  EVALUATION: 'Evaluation',
  RATING_CATEGORY: 'RatingCategory',
  RATING: 'Rating',
  USER: 'User',
  IMAGE: 'Image',
  VIDEO: 'Video',
  PRODUCT_BUYER_DATA: 'ProductBuyerData',
  PRODUCT_AUTHOR_DATA: 'Store',
  AUTHENTICATED_USER_MUTATION: 'AuthenticatedUserMutation'
};
