import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';
import './CollapsibleContent.scss';

class CollapsibleContent extends Component {
  static propTypes = {
    showContentLabel: PropTypes.string,
    hideContentLabel: PropTypes.string,
    collapsedContentHeight: PropTypes.number,
    children: PropTypes.node,
    showIcon: PropTypes.bool
  };

  static defaultProps = {
    showContentLabel: 'Show More',
    hideContentLabel: 'Hide',
    collapsedContentHeight: 105, // 5 lines of font-size 14
    showIcon: false
  };

  constructor(...props) {
    super(...props);
    this.state = { collapsed: false, shouldShowToggle: false };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-string-refs
    const { clientHeight } = this.refs.CollapsibleContent;

    const shouldCollapse = clientHeight > this.props.collapsedContentHeight;
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      shouldShowToggle: shouldCollapse,
      collapsed: shouldCollapse
    });
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    const { collapsed, shouldShowToggle } = this.state;

    const {
      showContentLabel,
      hideContentLabel,
      collapsedContentHeight,
      children,
      showIcon
    } = this.props;

    const style = {
      maxHeight: collapsed ? `${collapsedContentHeight}px` : undefined
    };

    const showContentBlock = (
      <span>
        <span className="CollapsibleContent__toggleLabel">{showContentLabel}</span>
        {showIcon && <Icon name="angle-down" />}
      </span>
    );

    const hideContentBlock = (
      <span>
        <span className="CollapsibleContent__toggleLabel">{hideContentLabel}</span>
        {showIcon && <Icon name="angle-up" />}
      </span>
    );

    return (
      <div
        className="CollapsibleContent"
        // eslint-disable-next-line react/no-string-refs
        ref="CollapsibleContent"
      >
        {children && (
          <div
            className={classnames({
              CollapsibleContent__collapsed: collapsed
            })}
            style={style}
          >
            {children}
          </div>
        )}
        {shouldShowToggle && (
          <div
            className="CollapsibleContent__toggle"
            data-testid="CollapsibleContent__toggle"
            onClick={this.toggleCollapsed}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this.toggleCollapsed();
              }
            }}
          >
            {collapsed ? showContentBlock : hideContentBlock}
          </div>
        )}
      </div>
    );
  }
}

export default CollapsibleContent;
