import { graphql } from 'react-apollo';
import readFragment from 'shared/decorators/apollo/readFragment';
import TYPE_NAMES from 'tpt_modules/products/typeNames';
import ReplyFields from 'tpt_modules/products/fragments/ReplyFields.graphql';
import ResourceFields from 'tpt_modules/products/fragments/ResourceFields.graphql';
import CommentFields from 'tpt_modules/products/fragments/CommentFields.graphql';
import EvaluationFields from 'tpt_modules/products/fragments/EvaluationFields.graphql';
import ProductCommentsQuery from 'tpt_modules/products/queries/ProductComments.graphql';
import QualityEvaluationStatusQuery from 'tpt_modules/products/queries/QualityEvaluationStatus.graphql';
import SellerEvaluationsQuery from 'tpt_modules/sellers/queries/SellerEvaluations.graphql';
import FilterEvaluationsByResource from 'tpt_modules/products/queries/FilterEvaluationsByResource.graphql';
import { trimBreakTags } from 'util/utils';
import {
  APOLLO_NETWORK_STATUS,
  COMMENT_SORT,
  COMMENT_TYPES,
  EVALUATIONS_AND_QUESTIONS_LIMIT
} from './constants';

export const commentFragments = [
  readFragment(
    {
      fragmentName: 'CommentFields',
      fragment: CommentFields,
      key: ({ commentId }) => `${TYPE_NAMES.COMMENT}:${commentId}`
    },
    (comment) => ({ comment: { ...comment, text: trimBreakTags(comment.text) } })
  ),
  readFragment(
    {
      fragment: ReplyFields,
      key: ({ commentId }) => `${TYPE_NAMES.COMMENT}:${commentId}`,
      skip: ({ comment }) => comment.parentId !== null
    },
    (comment, props) =>
      comment.reply ? { comment: { ...props.comment, childId: comment.reply.id } } : null
  )
];

export const resourceFragment = readFragment(
  {
    fragmentName: 'ResourceFields',
    fragment: ResourceFields,
    key: ({ itemId }) => `${TYPE_NAMES.PRODUCT}:${itemId}`
  },
  (resource) => {
    const { scoreAverage } = resource.ratings;
    // FIXME on API side: products.ratings.averageRatings.score is an integer;
    // comments.ratings.score is a String
    // eslint-disable-next-line no-param-reassign
    resource.ratings.scoreAverage = parseFloat(scoreAverage);
    return { resource };
  }
);

export const commentsUpdateQuery = (previousResult, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    return previousResult;
  }
  return {
    ...previousResult,
    productComments: {
      ...previousResult.productComments,
      comments: [
        ...previousResult.productComments.comments,
        ...fetchMoreResult.productComments.comments
      ]
    }
  };
};

export const commentsQueryProps = ({
  data: { loading, networkStatus, productComments, fetchMore }
}) => ({
  initialLoad:
    networkStatus === APOLLO_NETWORK_STATUS.LOADING ||
    networkStatus === APOLLO_NETWORK_STATUS.SET_VARIABLES,
  loading,
  productComments,
  loadMoreComments() {
    return fetchMore({
      variables: {
        offset: productComments.comments.length
      },
      updateQuery: commentsUpdateQuery
    });
  }
});

export const commentsQueryOptions = ({ itemId }) => ({
  ssr: false,
  variables: {
    itemId,
    limit: EVALUATIONS_AND_QUESTIONS_LIMIT,
    type: COMMENT_TYPES.QUESTIONS,
    sort: COMMENT_SORT.HELPFUL_AND_NEWEST_FIRST
  },
  notifyOnNetworkStatusChange: true
});

export const commentsQuery = graphql(ProductCommentsQuery, {
  options: commentsQueryOptions,
  props: commentsQueryProps
});

export const evaluationFragment = readFragment(
  {
    fragmentName: 'EvaluationFields',
    fragment: EvaluationFields,
    key: ({ evaluationId }) => `${TYPE_NAMES.EVALUATION}:${evaluationId}`,
    variables: ({ useResourceCatalog }) => ({
      useResourceCatalog: !!useResourceCatalog
    })
  },
  (evaluation) => ({ evaluation: { ...evaluation } })
);

export const evaluationStatusQueryProps = ({ data }) => ({
  evaluationStatus: {
    canEvaluate: !!data?.quality?.evaluationStatus?.[0]?.canEvaluate,
    isCompleted: !!data?.quality?.evaluationStatus?.[0]?.isCompleted,
    hasAccessedResource: !!data?.quality?.evaluationStatus?.[0]?.hasAccessedResource
  }
});

export const evaluationStatusQuery = graphql(QualityEvaluationStatusQuery, {
  skip: ({ auth, itemId }) => !auth || !itemId,
  options: ({ itemId }) => ({ variables: { productIds: [itemId] } }),
  props: evaluationStatusQueryProps
});

export const evaluationsUpdateLoadMoreQuery = (previousResult, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    return previousResult;
  }

  return {
    ...previousResult,
    quality: {
      ...previousResult.quality,
      filterEvaluationsByResource: {
        ...previousResult.quality.filterEvaluationsByResource,
        hasNext: fetchMoreResult.quality.filterEvaluationsByResource.hasNext,
        evaluations: [
          ...previousResult.quality.filterEvaluationsByResource.evaluations,
          ...fetchMoreResult.quality.filterEvaluationsByResource.evaluations
        ]
      }
    }
  };
};

// Temporary sellerEv is call resourceEv
// TODO: change query to correct endpoint

export const sellerEvaluationsUpdateQuery = (previousResult, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    return previousResult;
  }

  return {
    ...previousResult,
    quality: {
      ...previousResult.quality,
      sellerEvaluations: {
        ...previousResult.quality.sellerEvaluations,
        hasNext: fetchMoreResult.quality.sellerEvaluations.hasNext,
        evaluations: [
          ...previousResult.quality.sellerEvaluations.evaluations,
          ...fetchMoreResult.quality.sellerEvaluations.evaluations
        ]
      }
    }
  };
};

export const sellerEvaluationsQueryProps = ({
  data: { loading, networkStatus, quality, fetchMore }
}) => ({
  initialLoad:
    networkStatus === APOLLO_NETWORK_STATUS.LOADING ||
    networkStatus === APOLLO_NETWORK_STATUS.SET_VARIABLES,
  loading,
  sellerEvaluations: quality ? quality.sellerEvaluations : {},
  // name clash with other loadMoreComments function was causing pagination in other query to break
  // changed the name here. Eventually this will return both types of comments. The code below is
  // not being used yet. and will be used (or some form of it) when pagination is implemented for
  // new ratings and reviews
  loadMoreEvaluations() {
    return fetchMore({
      variables: {
        offset: quality.sellerEvaluations.evaluations.length
      },
      updateQuery: sellerEvaluationsUpdateQuery
    });
  }
});

export const sellerEvaluationsQueryOptions = ({ sellerId, sort, featureVariants }) => ({
  ssr: false,
  variables: {
    sellerId,
    limit: 10,
    offset: 0,
    sort,
    useResourceCatalog: featureVariants.rc_data_seller_store === 'on'
  },
  notifyOnNetworkStatusChange: true
});

export const sellerEvaluationsQuery = graphql(SellerEvaluationsQuery, {
  options: sellerEvaluationsQueryOptions,
  props: sellerEvaluationsQueryProps
});

export const filterEvaluationsQueryOptions = ({ resourceId }) => ({
  ssr: false,
  variables: {
    resourceId,
    grades: '',
    ratings: '',
    spedOptions: '',
    standards: '',
    limit: EVALUATIONS_AND_QUESTIONS_LIMIT,
    offset: 0,
    sortBy: 'weightedHelpfulCount'
  },
  notifyOnNetworkStatusChange: true
});

export const filterEvaluationsQuery = graphql(FilterEvaluationsByResource, {
  options: filterEvaluationsQueryOptions,
  props: ({ data: { loading, networkStatus, quality, fetchMore, refetch } }) => {
    const resourceEvaluations = quality ? quality.filterEvaluationsByResource : {};

    return {
      loading,
      initialLoad: networkStatus === APOLLO_NETWORK_STATUS.LOADING,
      resourceEvaluations,
      loadMoreEvaluations() {
        return fetchMore({
          variables: {
            offset: resourceEvaluations.evaluations.length
          },
          updateQuery: evaluationsUpdateLoadMoreQuery
        });
      },
      updateEvaluationsFilter: refetch
    };
  }
});
