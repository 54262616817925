import React from 'react';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import Text from 'components/Text';
import EvaluationStarRating from '../EvaluationStarRating';

import './EvaluationCategoryStarRating.scss';

const EvaluationCategoryStarRating = ({ category, rating }) => {
  const displayName = 'EvaluationCategoryStarRating';
  const categoryWrapper = `${displayName}__category`;

  return (
    <div className={displayName}>
      <div key={category} className={`${categoryWrapper}`}>
        <div className={`${categoryWrapper}__categoryName`}>
          <Text variant="bodySmall">{capitalize(category.replace('_', ' '))}</Text>
        </div>
        <EvaluationStarRating rating={rating} isTextLeft />
      </div>
    </div>
  );
};

EvaluationCategoryStarRating.propTypes = {
  category: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired
};

export default EvaluationCategoryStarRating;
