import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import LoginRedirectLink from 'components/LoginRedirectLink/LoginRedirectLink';
import MarkAsHelpfulButton from '../../components/MarkAsHelpfulButton';

export default class MarkAsHelpfulContainer extends Component {
  static propTypes = {
    helpfulCount: PropTypes.number.isRequired,
    hasCurrentUserMarkedHelpful: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    markAsHelpful: PropTypes.func.isRequired,
    unmarkAsHelpful: PropTypes.func.isRequired,
    canMarkAsHelpful: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);

    this.state = { hasUserToggled: false };
  }

  onClick() {
    const { markAsHelpful, unmarkAsHelpful, isLoggedIn, canMarkAsHelpful } = this.props;
    const { hasUserToggled } = this.state;

    if (!canMarkAsHelpful) return;

    if (!isLoggedIn) return;

    const hasUserMarkedHelpful = this.hasUserMarkedHelpful();

    if (hasUserMarkedHelpful) {
      unmarkAsHelpful();
    } else {
      markAsHelpful();
    }

    this.setState({ hasUserToggled: !hasUserToggled });
  }

  hasUserMarkedHelpful() {
    const { hasCurrentUserMarkedHelpful } = this.props;
    const { hasUserToggled } = this.state;

    return (
      (hasCurrentUserMarkedHelpful && !hasUserToggled) ||
      (!hasCurrentUserMarkedHelpful && hasUserToggled)
    );
  }

  adjustHelpfulCount() {
    const { helpfulCount, hasCurrentUserMarkedHelpful } = this.props;
    const { hasUserToggled } = this.state;

    const shouldManuallyAddOne = !hasCurrentUserMarkedHelpful && hasUserToggled;
    const shouldManuallySubtractOne = hasCurrentUserMarkedHelpful && hasUserToggled;

    let adjustedHelpfulCount = helpfulCount;

    if (shouldManuallyAddOne) {
      adjustedHelpfulCount += 1;
    }

    if (shouldManuallySubtractOne) {
      adjustedHelpfulCount -= 1;
    }

    return adjustedHelpfulCount;
  }

  render() {
    const { isLoggedIn, canMarkAsHelpful } = this.props;

    const content = (
      <div className="MarkAsHelpfulContainer">
        <MarkAsHelpfulButton
          helpfulCount={this.adjustHelpfulCount()}
          hasCurrentUserMarkedHelpful={this.hasUserMarkedHelpful()}
          onClick={() => this.onClick()}
          canMarkAsHelpful={canMarkAsHelpful}
        />
      </div>
    );

    return isLoggedIn ? content : <LoginRedirectLink>{content}</LoginRedirectLink>;
  }
}
