import { debounce, intersectionBy } from 'lodash';
import { addHours, isBefore, isEqual } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { ITEM_TYPE_ONLINE_RESOURCE, ITEM_TYPE_VIDEO } from 'config/constants';
import TYPE_NAMES from 'tpt_modules/products/typeNames';
import CommentFields from 'tpt_modules/products/fragments/CommentFields.graphql';
import ProductCommentsQuery from 'tpt_modules/products/queries/ProductComments.graphql';
import {
  COMMENT_SORT,
  EVALUATIONS_AND_QUESTIONS_LIMIT,
  EXCLUDED_REVIEW_BLACKOUT_RESOURCE_TYPES
} from './constants';

export const debounced = (fn, timeout) => debounce(fn, timeout);

export function userDataToAuth(userData) {
  if (!userData || !userData.member || !userData.member.id) {
    return null;
  }

  const { id, store: { url, icon } = {}, userProfile: { displayName } = {} } = userData.member;

  return {
    id,
    displayName,
    storeUrl: url,
    isSeller: !!url,
    avatar: icon,
    isActive: true
  };
}

/**
 * Verifies if a comment is the start of a thread
 *
 * @param { !object } comment
 * @return { boolean }
 */
export function isRoot(comment) {
  return comment && !comment.parentId;
}

/**
 * Verifies if a comment contains a reply
 * @param { !object } comment
 * @return { boolean }
 */
export function hasReply(comment) {
  return comment && comment.childId;
}

function notifyFetchError(url, request, response) {
  const { Bugsnag } = window;
  if (Bugsnag) {
    Bugsnag.notify(
      {
        name: 'Comment Mutations | HTTP POST Error',
        message: 'Response is not ok'
      },
      {
        severity: 'error',
        metaData: {
          'Graph query details': { url, request, response }
        }
      }
    );
  }

  throw new Error('Comment Mutations | HTTP POST Error');
}

export const http = {
  async post(url, body) {
    const requestOptions = { method: 'POST' };
    const bodyOptions = body && {
      headers: {
        'Content-Type': 'application/json'
      },
      body
    };

    const response = await __TPT__.fetch(url, { ...requestOptions, ...bodyOptions });
    if (!response.ok) {
      notifyFetchError(url, body);
    }

    const data = await response.json();
    if (data.error) {
      notifyFetchError(url, body, data);
    }

    return data;
  },

  get(url) {
    return __TPT__.fetch(url);
  }
};

export function findIndexById(list, fromId) {
  const fromIdInt = parseInt(fromId, 10);
  return list.findIndex(({ id }) => fromIdInt === parseInt(id, 10));
}

export const readComment = (client, id) =>
  client.readFragment({
    fragmentName: 'CommentFields',
    fragment: CommentFields,
    id: `${TYPE_NAMES.COMMENT}:${id}`
  });

export const writeComment = (client, id, data) =>
  client.writeFragment({
    fragmentName: 'CommentFields',
    fragment: CommentFields,
    id: `${TYPE_NAMES.COMMENT}:${id}`,
    data
  });

export const queryCommentList = (client, itemId, type) =>
  client.readQuery({
    query: ProductCommentsQuery,
    variables: {
      itemId,
      type,
      limit: EVALUATIONS_AND_QUESTIONS_LIMIT,
      sort: COMMENT_SORT.HELPFUL_AND_NEWEST_FIRST
    }
  });

export const updateCommentList = (client, itemId, type, data) =>
  client.writeQuery({
    query: ProductCommentsQuery,
    variables: {
      itemId,
      type,
      limit: EVALUATIONS_AND_QUESTIONS_LIMIT,
      sort: COMMENT_SORT.HELPFUL_AND_NEWEST_FIRST
    },
    data
  });

export function hasValidText(text) {
  return Boolean(text.match(new RegExp('\\w', 'ig')));
}

export function canNotify(comment) {
  return !comment || isRoot(comment);
}

export function isExcludedFromReviewBlackoutPeriod({ types }) {
  return intersectionBy(EXCLUDED_REVIEW_BLACKOUT_RESOURCE_TYPES, types, 'sphinxId').length > 0;
}

/**
 * Determines whether 24 hours or more has passed since the specified date-time
 * @param {Date} datetimeWithZone - ISO 8601-formatted date-time with timezone specified
 * @param {function} [utcNowFunc] - function to use to return "current" time in UTC for comparison purposes (optional, defaults to Date.now())
 * @return {boolean} - true if more than 24 hours have passed between the specified time and now
 */
export function hasBeenTwentyFourHoursSince(datetimeWithZone, utcNowFunc = Date.now) {
  const twentyFourHoursAgo = addHours(utcNowFunc(), -24);
  const targetDatetime = toDate(datetimeWithZone);

  return (
    isBefore(targetDatetime, twentyFourHoursAgo) || isEqual(targetDatetime, twentyFourHoursAgo)
  );
}

function isVideoOrOnlineResource(itemType) {
  return itemType === ITEM_TYPE_VIDEO || itemType === ITEM_TYPE_ONLINE_RESOURCE;
}

function isOutsideBlackoutPeriod(resource, datetime) {
  return hasBeenTwentyFourHoursSince(datetime) || isExcludedFromReviewBlackoutPeriod(resource);
}

export function purchasedAndDownloaded({ evaluationStatus, resource }) {
  const { buyerData, bundle } = resource;
  const hasOnlyVideoOrOnlineResourceChildren = bundle?.includedItemTypes?.every(
    isVideoOrOnlineResource
  );

  if (isVideoOrOnlineResource(resource.itemType) || hasOnlyVideoOrOnlineResourceChildren) {
    return true;
  }

  const acquiredAt = buyerData?.purchasedAt || buyerData?.downloadedAt;
  return (
    buyerData?.isPurchased &&
    evaluationStatus?.hasAccessedResource &&
    isOutsideBlackoutPeriod(resource, acquiredAt)
  );
}
