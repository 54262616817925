import React from 'react';
import { PropTypes } from 'prop-types';
import './EvaluationStudentGradesSection.scss';
import { GRADES, SPECIAL_ED_OPTIONS } from '../constants';

const EvaluationStudentGradesSection = ({ gradesUsedWith, specialEdOptions }) => {
  let specialEdText = '';
  const showSpecialEdOptions = specialEdOptions && specialEdOptions.length > 0;
  const showGrades =
    gradesUsedWith && gradesUsedWith.length > 0 && !gradesUsedWith.includes('none');

  let grades = GRADES.none.label;

  // filters, sorts, and formats grades 1-12
  const formatSchoolGrades = () => {
    const nonNumberGrades = ['Early Childhood', 'PreK', 'K'];

    const filteredGrades = gradesUsedWith.filter(
      (grade) => grade !== 'higher' && grade !== 'adult' // removes non school grades
    );
    const sortedGrades = filteredGrades.sort((a, b) => GRADES[a].index - GRADES[b].index);
    const formattedGrades = sortedGrades.map((grade) => GRADES[grade].label);

    const numberOfSchoolGrades = formattedGrades.length;
    const lastIndex = numberOfSchoolGrades - 1;

    const gradesInString = formattedGrades.join(', ').replace(/,\s*$/, ' '); // replaces the final ","" with a space " "

    if (nonNumberGrades.includes(formattedGrades[lastIndex])) {
      // checks after sorting if the last grade is a non-number grade.
      return gradesInString;
    }

    const pluralizeGrade = parseInt(numberOfSchoolGrades, 10) > 1 ? 'grades' : 'grade';

    return `${gradesInString} ${pluralizeGrade}`;
  };

  // filters out all school grades in the list and formats it
  const formatOtherGrades = () => {
    const otherGrades = gradesUsedWith
      .filter((grade) => grade === 'higher' || grade === 'adult')
      .map((grade) => GRADES[grade].label);
    return otherGrades && otherGrades.join(', ').replace(/,\s*$/, ' ');
  };

  const formatGradesText = () => {
    const schoolGrades = formatSchoolGrades();
    const otherGrades = formatOtherGrades();

    const schoolGradeList = schoolGrades
      .split(',')
      .filter((element) => element !== ' grades' && element !== ' grade');

    const hasSchoolGrades = schoolGradeList.length > 0;
    const hasOtherGrades = otherGrades.length > 0 && otherGrades.split(',').length > 0;

    if (hasSchoolGrades && hasOtherGrades) {
      return `${schoolGrades}, ${otherGrades}`;
    }

    if (hasSchoolGrades) {
      return schoolGrades;
    }

    return otherGrades;
  };

  if (showGrades) {
    grades = formatGradesText();
  }

  const formatSpecialEdOptions = () => {
    const formatedSpecialEdOptions = specialEdOptions.map((option) => SPECIAL_ED_OPTIONS[option]);
    if (specialEdOptions.length > 1) {
      const lastIndex = specialEdOptions.length - 1;
      formatedSpecialEdOptions[lastIndex] = `and ${formatedSpecialEdOptions[lastIndex]}`;
    }
    const specialEdOptionsString = formatedSpecialEdOptions.join(', ').replace(/,\s*$/, ' '); // replaces the final ","" with a space " "

    return `Primarily students ${specialEdOptionsString}`;
  };

  if (showSpecialEdOptions) {
    specialEdText = formatSpecialEdOptions();
  }

  return (
    <div className="EvaluationStudentGradesSection">
      <div>
        <span className="EvaluationStudentGradesSection__grades">{grades}</span>
        {showSpecialEdOptions && <div data-testid="special_ed_options"> {specialEdText}</div>}
      </div>
    </div>
  );
};

EvaluationStudentGradesSection.propTypes = {
  gradesUsedWith: PropTypes.arrayOf(PropTypes.string),
  specialEdOptions: PropTypes.arrayOf(PropTypes.string)
};

export default EvaluationStudentGradesSection;
