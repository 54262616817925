import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DSSpinner from '@teachers/design-system/components/Spinner';
import '@teachers/design-system/components/Spinner/Spinner.scss';
import './LoadingAnimation.scss';

const LoadingAnimation = ({ isVisible, centered, className, small }) => (
  <div
    className={classnames(
      'LoadingAnimation',
      {
        LoadingAnimation__visible: isVisible,
        LoadingAnimation__centered: centered
      },
      className
    )}
  >
    <div className="LoadingAnimation__spinnerContainer">
      <DSSpinner animation="border" variant="secondary" size={small ? 'sm' : null} />
    </div>
  </div>
);

LoadingAnimation.propTypes = {
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  centered: PropTypes.bool,

  /* If true, will set the size of the Spinner to "sm" */
  small: PropTypes.bool
};

LoadingAnimation.defaultProps = {
  isVisible: false,
  centered: false,
  className: undefined,
  small: false
};

export default LoadingAnimation;
