import React from 'react';
import { PropTypes } from 'prop-types';
import { StandardsHierarchyPropType } from 'domains/Comments/proptypes';
import Text from 'components/Text';
import { JURISDICTIONS } from 'shared/modules/education-standards/const';
import StandardSlider from '../StandardSlider';
import './EvaluationStandardsAlignmentSection.scss';

import { ALIGNMENT_RATING } from '../constants';

const EvaluationStandardsAlignmentSection = ({ standardsAlignmentScore, standardsTaught }) => {
  const standardsCompList = [];

  const findJurisdictionNotation = (parentIds) => {
    let jurisdiction;

    parentIds.forEach((pid) => {
      if (JURISDICTIONS[pid]) {
        jurisdiction = JURISDICTIONS[pid];
      }
    });

    if (jurisdiction) {
      return jurisdiction.notation;
    }

    return null;
  };

  const standardWithData = standardsTaught
    .filter((standard) => standardsAlignmentScore[standard.id] && standard)
    .map((standard) => {
      const restructureStandard = standard;
      const alignment = standardsAlignmentScore[standard.id];
      restructureStandard.alignment = ALIGNMENT_RATING[alignment];
      return restructureStandard;
    });

  const sortedStandards = standardWithData.sort((a, b) => b.alignment.value - a.alignment.value);

  sortedStandards.forEach((standard) => {
    const { start, end, name, id } = standard.alignment;
    const jurisdictionNotation = findJurisdictionNotation(standard.parentIds);

    if (!standard.alignment) {
      // missing from standards taught array, this is a data error ignoring this standard.
      return;
    }
    const notAligned = standardsAlignmentScore[standard.id] === id;

    let tooltipText = `${standard.name}: ${name}\n\n${standard.descriptionText}`;

    if (jurisdictionNotation) {
      tooltipText = `${jurisdictionNotation} | ${tooltipText}`;
    }

    const comp = (
      <div
        key={standard.id}
        className="EvaluationStandardsAlignmentSection__standard"
        data-tooltip={tooltipText}
      >
        <StandardSlider min={0} max={4} start={start} end={end} notAligned={notAligned} />
        <div className="EvaluationStandardsAlignmentSection__standard__title">
          {jurisdictionNotation && (
            <Text component="span" color="textSecondary" variant="microcopy" inline>
              {`${jurisdictionNotation} | `}
            </Text>
          )}
          <Text component="span" variant="microcopy" strong inline>
            {standard.name}
          </Text>
        </div>
      </div>
    );
    standardsCompList.push(comp);
  });

  return <div className="EvaluationStandardsAlignmentSection">{standardsCompList}</div>;
};

EvaluationStandardsAlignmentSection.propTypes = {
  standardsAlignmentScore: PropTypes.shape().isRequired,
  standardsTaught: PropTypes.arrayOf(StandardsHierarchyPropType).isRequired
};

export default EvaluationStandardsAlignmentSection;
