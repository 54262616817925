import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Rating from 'shared/components/Rating';

import './EvaluationStarRating.scss';

const componentName = 'EvaluationStarRating';
const componentWrapper = `${componentName}__wrapper`;
const categoryWrap = `${componentWrapper}__categoryWrap`;

const EvaluationStarRating = ({
  rating,
  text,
  subText,
  hasSubTextSpacing,
  children,
  isTextLeft,
  color = 'blue',
  size = 'medium'
}) => (
  <div className={componentName}>
    <div className={componentWrapper}>
      {isTextLeft && <div className={categoryWrap}>{children || text}</div>}
      <Rating value={rating || 0} max={5} starColor={color} starSize={size} />
      {!isTextLeft && <div className={categoryWrap}>{children || text}</div>}
    </div>
    {subText && (
      <div
        className={classnames({
          [`${componentName}__subText`]: true,
          [`${componentName}__subText--spacing`]: hasSubTextSpacing
        })}
      >
        {subText}
      </div>
    )}
  </div>
);

EvaluationStarRating.defaultProps = {
  text: '',
  isTextLeft: false
};

EvaluationStarRating.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string,
  subText: PropTypes.string,
  hasSubTextSpacing: PropTypes.bool,
  children: PropTypes.node,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isTextLeft: PropTypes.bool
};

export default EvaluationStarRating;
