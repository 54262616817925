import React from 'react';
import { PropTypes } from 'prop-types';
import Icon from 'components/Icon';
import Text from 'components/Text';
import Anchor from 'components/Anchor';
import './EditButton.scss';

const EditButton = ({ resourceId }) => (
  <Anchor to={`/Evaluation/${resourceId}/edit`} forceReload>
    <div className="EditButton">
      <Icon name="pencil-square-o" className="EditButton__icon" />
      <Text variant="span" className="EditButton__text">
        Edit
      </Text>
    </div>
  </Anchor>
);

EditButton.propTypes = {
  resourceId: PropTypes.string.isRequired
};

export default EditButton;
