import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import './TextField.scss';

const TextField = ({ placeholder, value, tall, visuallyHiddenLabel, id }) => (
  <div>
    {visuallyHiddenLabel && (
      <label className="accessibility-sr-only" htmlFor={id}>
        {visuallyHiddenLabel}
      </label>
    )}
    <Field
      name="text"
      className={classnames('CommentTextField', {
        CommentTextField__tall: tall
      })}
      component="textarea"
      type="textarea"
      placeholder={placeholder}
      value={value}
      id={id}
    />
  </div>
);

TextField.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  tall: PropTypes.bool,

  /** FIXME: all <TextFields> should have a corresponding label for accessibility. */
  visuallyHiddenLabel: PropTypes.string,
  id: PropTypes.string
};

export default TextField;
