import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { pickHTMLProps } from 'pick-react-known-prop';
import { reduxForm, Form as ReduxForm, propTypes as rfPropTypes } from 'redux-form';
import { compose, withProps } from 'recompose';

// A stand-in for `@reduxForm(({ formId , ... }) => ({ form: formId, ... }))`.
// (This'll allow us to freely pass in properties rather than pass in at design time.)
function reduxFormCompose(ComposedComponent) {
  return compose(
    withProps(({ formId, initialValues, enableReinitialize, asyncValidate, asyncBlurFields }) => ({
      form: formId,
      initialValues,
      enableReinitialize,
      asyncValidate,
      asyncBlurFields
    })),
    reduxForm()
  )(ComposedComponent);
}

export class Form extends Component {
  static propTypes = {
    children: PropTypes.node,
    formId: PropTypes.string.isRequired, // identifier in the Redux store
    onSubmitCb: PropTypes.func, // post-submission callback
    resetOnSubmit: PropTypes.bool, // revert to initial values after submission?
    ...rfPropTypes
  };

  static childContextTypes = {
    formId: PropTypes.string.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired
  };

  static defaultProps = {
    onSubmitCb: () => {},
    resetOnSubmit: false
  };

  getChildContext() {
    const { formId, pristine, submitting } = this.props;

    return {
      formId,
      pristine,
      submitting
    };
  }

  handleSubmit = (values) => {
    const { resetOnSubmit, onSubmitCb, reset } = this.props;

    if (resetOnSubmit) {
      reset();
    }

    return onSubmitCb(values);
  };

  render() {
    const { children, formId, handleSubmit, ...rest } = this.props;
    const attributes = pickHTMLProps(rest);

    return (
      <ReduxForm
        className="Form"
        form={formId}
        noValidate
        onSubmit={handleSubmit(this.handleSubmit)}
        {...attributes}
      >
        {children}
      </ReduxForm>
    );
  }
}

export default reduxFormCompose(Form);
