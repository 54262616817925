export const STUDENT_FACING_RESOURCE = 'student_facing_resource';
export const STUDENT_FACING_RESOURCE_IN_CLASS_LEARNING =
  'student_facing_resource_in_class_learning';
export const STUDENT_FACING_RESOURCE_DISTANCE_LEARNING =
  'student_facing_resource_distance_learning';
export const STUDENT_FACING_RESOURCE_PARENT_AT_HOME_LEARNING =
  'student_facing_resource_parent_at_home_learning';
export const ALL_STUDENT_FACING_RESOURCE_OPTIONS = [
  STUDENT_FACING_RESOURCE,
  STUDENT_FACING_RESOURCE_IN_CLASS_LEARNING,
  STUDENT_FACING_RESOURCE_DISTANCE_LEARNING,
  STUDENT_FACING_RESOURCE_PARENT_AT_HOME_LEARNING
];

export const SATISFACTION_RATING = {
  not_at_all: {
    label: 'Not at all satisfied',
    value: 1
  },
  slightly: {
    label: 'Slightly satisfied',
    value: 2
  },
  moderately: {
    label: 'Moderately satisfied',
    value: 3
  },
  very: {
    label: 'Very satisfied',
    value: 4
  },
  extremely: {
    label: 'Extremely satisfied',
    value: 5
  }
};

export const GRADES = {
  early_childhood: { label: 'Early Childhood', index: -1 },
  prek: { label: 'PreK', index: 0 },
  k: { label: 'K', index: 1 },
  1: { label: '1st', index: 2 },
  2: { label: '2nd', index: 3 },
  3: { label: '3rd', index: 4 },
  4: { label: '4th', index: 5 },
  5: { label: '5th', index: 6 },
  6: { label: '6th', index: 7 },
  7: { label: '7th', index: 8 },
  8: { label: '8th', index: 9 },
  9: { label: '9th', index: 10 },
  10: { label: '10th', index: 11 },
  11: { label: '11th', index: 12 },
  12: { label: '12th', index: 13 },
  higher: { label: 'Higher Education', index: 14 },
  adult: { label: 'Adult Education', index: 15 },
  none: { label: 'Grade not selected', index: 16 }
};

export const ALIGNMENT_RATING = {
  not_aligned: { start: 0, end: 0, name: 'Not aligned', id: 'not_aligned', value: 1 },
  somewhat_aligned: { start: 0, end: 1, name: 'Somewhat aligned', value: 2 },
  moderately_aligned: { start: 0, end: 2, name: 'Moderately aligned', value: 3 },
  very_aligned: { start: 0, end: 3, name: 'Very aligned', value: 4 },
  extremely_aligned: { start: 0, end: 4, name: 'Completely aligned', value: 5 }
};

export const ENGAGEMENT_RATING = {
  agree_strong: { start: 5, end: 6, label: 'Strongly agree' },
  agree: { start: 4, end: 5, label: 'Agree' },
  neither: { start: 3, end: 4, label: 'Neither agree nor disagree' },
  disagree: { start: 2, end: 3, label: 'Disagree' },
  disagree_strong: { start: 1, end: 2, label: 'Strongly disagree' }
};

export const SPECIAL_ED_OPTIONS = {
  learning_difficulties: 'with learning difficulties',
  disabilities: 'with mild to severe disabilities',
  autism: 'on the autism spectrum',
  bilinguals: 'that are emerging bilinguals/ ELs/ ESOLs/ ENLs'
};

export const TRACK_EDIT_EVENT = {
  eventCat: 'Product Page',
  eventAct: 'Edited Review',
  eventLbl: 'new_evaluation_edit_button_clicked'
};

export const LEGACY_EVALUATION_CATEGORIES = [
  'clarity',
  'creativity',
  'thoroughness',
  'practicality',
  'accuracy',
  'overall_quality'
];

// We want to show 10 lines of text before collapsing which is around 245px
export const SHOW_LINE_HEIGHT = 245;
