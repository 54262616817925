import PropTypes from 'prop-types';
import { COMMENT_TYPES, RESOURCE_TYPES } from './constants';

export const AuthPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  storeUrl: PropTypes.string,
  isSeller: PropTypes.bool,
  avatar: PropTypes.shape({
    location: PropTypes.string
  }),
  isActive: PropTypes.bool
});

export const RatingPropType = PropTypes.shape({
  category: PropTypes.shape({
    name: PropTypes.string
  }),
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
});

export const CommentPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  created: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf(Object.values(COMMENT_TYPES)),
  notify: PropTypes.bool,
  helpfulness: PropTypes.number,
  hasUserMarkedHelpful: PropTypes.bool,
  parentId: PropTypes.number,
  itemId: PropTypes.number,
  userId: PropTypes.number,
  ratings: PropTypes.arrayOf(RatingPropType),
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string,
    displayName: PropTypes.string,
    storeUrl: PropTypes.string,
    isSeller: PropTypes.bool,
    avatar: PropTypes.shape({
      location: PropTypes.string
    })
  })
});

export const ResourcePropType = PropTypes.shape({
  itemType: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
  buyerData: PropTypes.shape({
    isPurchased: PropTypes.bool
  }),
  author: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  ratings: PropTypes.shape({
    count: PropTypes.number,
    averageRatings: PropTypes.arrayOf(RatingPropType),
    scoreAverage: PropTypes.string
  }),
  evaluationRating: PropTypes.shape({
    count: PropTypes.number,
    v1EvaluationsCount: PropTypes.number,
    scoreAverage: PropTypes.string
  })
});

export const ApolloClientPropType = PropTypes.shape({
  readFragment: PropTypes.func,
  writeFragment: PropTypes.func
});

export const StandardsHierarchyPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  descriptionText: PropTypes.string,
  parentIds: PropTypes.arrayOf(PropTypes.string)
});

export const SellerReplyPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string,
    displayName: PropTypes.string,
    storeUrl: PropTypes.string,
    isSeller: PropTypes.bool,
    avatar: PropTypes.shape({
      location: PropTypes.string
    })
  }),
  text: PropTypes.string,
  updatedAt: PropTypes.string
});

export const EvaluationPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  signals: PropTypes.object,
  signalsDataEducationStandards: PropTypes.arrayOf(StandardsHierarchyPropType),
  resourceId: PropTypes.string,
  userId: PropTypes.string,
  sellerReply: SellerReplyPropType,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    username: PropTypes.string,
    displayName: PropTypes.string,
    storeUrl: PropTypes.string,
    isSeller: PropTypes.bool,
    avatar: PropTypes.shape({
      location: PropTypes.string
    })
  }),
  resource: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string
  })
});

export const LegacyRatingPropType = PropTypes.shape({
  clarity: PropTypes.string,
  creativity: PropTypes.string,
  thoroughness: PropTypes.string,
  practicality: PropTypes.string,
  accuracy: PropTypes.string,
  overall_quality: PropTypes.string
});
