import PropTypes from 'prop-types';
import React from 'react';
import { withApollo } from 'react-apollo';
import { isFunction, omit } from 'lodash';

const omittedFields = ['__typename', 'client'];

/* eslint-disable max-len */
/**
 * Read data from the Apollo cache identified by a particular fragment
 * (This is a stand-in till react-apollo gets its act together
 * and implements its own decorators...tsk tsk!)
 *
 * @param { !object } opts
 *   @param { !object|!Function } opts.fragment  // GraphQL document tree
 *   @param { !string|!Function } opts.key       // Identifier of the fragment (from constructApolloKey)  // eslint-disable-line max-len
 * @param { Function= } mapDataToProps          // Callback function to handle fragment data
 *                                              // This should return an object to pass down to the WrappedComponent // eslint-disable-line max-len
 *                                              // If this is undefined, then just pass down all the data as 'fragmentCacheData' // eslint-disable-line max-len
 */
/* eslint-enable max-len */
export default function readFragment(
  { fragment, key, skip, fragmentName, variables },
  mapDataToProps
) {
  return (WrappedComponent) => {
    const ReadFragmentComponent = (props) => {
      const apolloFragment = isFunction(fragment) ? fragment(props) : fragment;
      const apolloKey = isFunction(key) ? key(props) : key;
      const shouldSkip = isFunction(skip) ? skip(props) : skip;
      const apolloVariables = isFunction(variables) ? variables(props) : variables;

      if (shouldSkip) {
        return <WrappedComponent {...props} />;
      }

      let data;
      try {
        data = props.client.readFragment(
          { fragment: apolloFragment, id: apolloKey, fragmentName, variables: apolloVariables }
          // TODO: add boolean argument here for reading optimistic data
          // https://github.com/apollographql/apollo-client/issues/2222
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Could not readFragment ${fragmentName} in ${WrappedComponent.displayName}`);
        throw e;
      }

      const mappedData = isFunction(mapDataToProps)
        ? mapDataToProps(data, props)
        : { fragmentCacheData: data };

      const childProps = omit(
        {
          ...props,
          ...mappedData
        },
        omittedFields
      );

      return <WrappedComponent {...childProps} />;
    };

    ReadFragmentComponent.WrappedComponent = WrappedComponent;
    ReadFragmentComponent.displayName = `ReadFragment(${
      WrappedComponent.displayName || WrappedComponent.name
    })`;

    ReadFragmentComponent.propTypes = {
      client: PropTypes.object.isRequired
    };

    return withApollo(ReadFragmentComponent);
  };
}
