/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import createApolloClient from 'lib/createApolloClient';
import IntlProvider from 'react-intl/src/components/provider';
import localeMessages from 'util/localeMessages';
import { Provider } from 'react-redux';
import createStore from 'lib/createStore';
import createHistory from 'lib/history';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';

import StoreCommentsContainer from 'domains/Comments/containers/StoreCommentsContainer';

const req = {
  headers: {
    cookie: window.document.cookie
  }
};

const apolloClient = createApolloClient({
  url: '',
  req,
  opts: {
    credentials: 'same-origin'
  }
});

const history = createHistory();
const store = createStore(JSON.parse(window.__TPT__.dumpStore()), {}, [routerMiddleware(history)]);
syncHistoryWithStore(history, store);

const storeCommentsContainer = document.querySelector('#react-store-comments');
if (storeCommentsContainer) {
  const sellerId = storeCommentsContainer.getAttribute('data-store-id');
  ReactDOM.render(
    /* eslint-disable react/jsx-filename-extension */
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <IntlProvider locale="en-US" messages={localeMessages['en-US']}>
          <StoreCommentsContainer sellerId={sellerId} />
        </IntlProvider>
      </Provider>
    </ApolloProvider>,
    /* eslint-enable react/jsx-filename-extension */
    storeCommentsContainer
  );
}
