
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CommentFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Comment"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"created"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"text"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"notify"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"helpfulness"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"hasUserMarkedHelpful"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"parentId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"itemId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"userId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserFields"},"directives":[]}]}}]}}],"loc":{"start":0,"end":204}};
    doc.loc.source = {"body":"#import './UserFields.graphql'\nfragment CommentFields on Comment {\n  id\n  created\n  text\n  type\n  notify\n  helpfulness\n  hasUserMarkedHelpful\n  parentId\n  itemId\n  userId\n  user {\n    ...UserFields\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('./UserFields.graphql').definitions));


      module.exports = doc;
    
