import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import FlexBox from 'components/FlexBox';
import Avatar from 'components/Avatar';
import { AuthPropType, SellerReplyPropType } from 'domains/Comments/proptypes';
import SellerReply from '../EvaluationSellerReply';
import ReplyButton from '../ReplyButton';
import ReplyForm from '../ReplyForm';

import './ReplyContainer.scss';

const ReplyContainer = ({ resourceSellerId, evaluationId, auth, sellerReply, isMobile }) => {
  const [mode, setMode] = React.useState('READ');
  const [tall, setTall] = React.useState(false);
  const isResourceOwnedBySeller = auth && auth.id === resourceSellerId;

  if (mode === 'READ' && !sellerReply) {
    return isResourceOwnedBySeller ? <ReplyButton onClick={() => setMode('REPLY')} /> : null;
  }

  const user = sellerReply && sellerReply.user ? sellerReply.user : auth;
  const avatarSrc = user && user.avatar && user.avatar.location ? user.avatar.location : null;
  const avatarAlt = user && user.displayName ? user.displayName : 'Seller';

  return (
    <FlexBox className="ReplyContainer" flexDirection="row">
      <Avatar
        className="ReplyContainer__avatar"
        src={avatarSrc}
        size="mobile"
        alt={avatarAlt}
        isMobile={isMobile}
      />
      <div
        className={classnames('ReplyContainer__content', {
          'ReplyContainer__content--reply': mode === 'REPLY'
        })}
      >
        {mode === 'READ' && (
          <SellerReply
            isResourceOwnedBySeller={isResourceOwnedBySeller}
            sellerReply={sellerReply}
            editReply={() => {
              setMode('REPLY');
              setTall(true);
            }}
            isMobile={isMobile}
          />
        )}
        {mode === 'REPLY' && (
          <ReplyForm
            auth={auth}
            evaluationId={evaluationId}
            sellerReply={sellerReply}
            hideForm={() => {
              setMode('READ');
              setTall(false);
            }}
            isMobile={isMobile}
            tall={tall}
          />
        )}
      </div>
    </FlexBox>
  );
};

ReplyContainer.propTypes = {
  auth: AuthPropType.isRequired,
  evaluationId: PropTypes.string.isRequired,
  resourceSellerId: PropTypes.string.isRequired,
  sellerReply: SellerReplyPropType,
  isMobile: PropTypes.bool
};

export default ReplyContainer;
