import React from 'react';
import { LegacyRatingPropType } from 'domains/Comments/proptypes';
import Text from 'components/Text';
import EvaluationCategoryStarRating from '../EvaluationCategoryStarRating';

import './EvaluationStarRatingList.scss';

const EvaluationStarRatingList = ({ ratings }) => {
  const displayName = 'EvaluationStarRatingList';
  const categoryList = Object.keys(ratings);

  return (
    <div className={displayName}>
      {categoryList.map((category) => (
        <div
          key={category}
          className={`${displayName}__item`}
          data-testid={`${category}: ${ratings[category]}`}
        >
          <EvaluationCategoryStarRating category={category} rating={ratings[category]} />
          {ratings[category] === '0.00' && (
            <Text variant="bodySmall" className={`${displayName}__text`}>
              N/A
            </Text>
          )}
        </div>
      ))}
    </div>
  );
};

EvaluationStarRatingList.propTypes = {
  ratings: LegacyRatingPropType.isRequired
};

export default EvaluationStarRatingList;
