import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import GraphRange from '../GraphRange';
import './StandardSlider.scss';

const StandardSlider = ({ min, max, start, end, notAligned }) => {
  const classes = classnames('StandardSlider', { StandardSlider__notAligned: notAligned });
  return (
    <div className={classes}>
      <GraphRange min={min} max={max} start={start} end={end} />
    </div>
  );
};

StandardSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  notAligned: PropTypes.bool.isRequired
};

export default StandardSlider;
