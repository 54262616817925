import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import './Rating.scss';

// returns a sequence from 0 to max - 1
const seq = (to) => Array(...Array(to));

// returns the star fill offset
const fillOffset = (idx, value) => {
  let offset = 0;
  if (idx + 1 <= Math.floor(value)) {
    offset = 100;
  } else if (idx + 1 === Math.ceil(value)) {
    offset = (value - idx) * 100;
  }

  return `${offset}%`;
};

// A star rating system.
const Rating = ({ className, value = 0, max = 4, starSize, starColor }) => {
  const pixelSize = {
    extraSmall: '14px',
    small: '17px',
    medium: '18px',
    large: '20px',
    extraLarge: '22px',
    xxLarge: '24px'
  };

  const hexColor = {
    green: '#0aa96c',
    blue: '#2885BF',
    gold: '#eab536',
    black: '#000000',
    schoolsBlue: '#2b3786',
    ratingsBlue: '#1C79CD'
  };

  const color = starColor || 'gold';
  const size = starSize || 'medium';

  return (
    <span className={classnames('Rating', className)}>
      {seq(max).map((_, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="Rating__star" key={idx}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={pixelSize[size]}
            height={pixelSize[size]}
            viewBox="0 0 460 550"
            version="1.1"
          >
            <svg width="100%" height="100%">
              <polygon
                fill="#000"
                fillOpacity="0.15"
                points="229,0 300,134 459,167 344,279 371,437 229,363 87,437 114,279 0,167 158,144"
              />
            </svg>
            <svg width={fillOffset(idx, value)} height="100%">
              <polygon
                fill={hexColor[color]}
                points="229,0 300,134 459,167 344,279 371,437 229,363 87,437 114,279 0,167 158,144"
              />
            </svg>
          </svg>
        </div>
      ))}
    </span>
  );
};

Rating.propTypes = {
  /**
   * For adding css styling.
   */
  className: PropTypes.string,
  /**
   * The rating value (number of stars).
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * The total number of stars possible.
   */
  max: PropTypes.number,
  /**
   * Size of star (options: small, large).
   */
  starSize: PropTypes.string,
  /**
   * Color of star (options: gold, blue, green).
   */
  starColor: PropTypes.string
};

export default Rating;
