import React from 'react';
import { PropTypes } from 'prop-types';
import { compose } from 'react-apollo';
import EvaluationDisplay from 'domains/Comments/components/Evaluation/EvaluationDisplay';
import EvaluationLegacyDisplay from 'domains/Comments/components/Evaluation/EvaluationLegacyDisplay';
import { evaluationFragment } from 'domains/Comments/queries';
import { EVALUATION_TYPES } from 'domains/Comments/constants';
import { EvaluationPropType, AuthPropType } from 'domains/Comments/proptypes';
import 'domains/Comments/Evaluations.scss';

const PureEvaluationContainer = ({
  resourceSellerId,
  evaluation,
  auth,
  showProductLink,
  isMobile,
  isLegacyGrid
}) => {
  const { LEGACY_EVALUATION_TYPE } = EVALUATION_TYPES;

  return (
    <div>
      {evaluation && evaluation.evaluationTypeId === LEGACY_EVALUATION_TYPE ? (
        <EvaluationLegacyDisplay
          auth={auth}
          evaluation={evaluation}
          resourceSellerId={resourceSellerId}
          showProductLink={showProductLink}
          isMobile={isMobile}
        />
      ) : (
        <EvaluationDisplay
          evaluation={evaluation}
          resourceSellerId={resourceSellerId}
          auth={auth}
          showProductLink={showProductLink}
          isMobile={isMobile}
          isLegacyGrid={isLegacyGrid}
        />
      )}
    </div>
  );
};

PureEvaluationContainer.defaultProps = {
  evaluation: {
    evaluation: {
      evaluationTypeId: null
    }
  },
  isLegacyGrid: false,
  showProductLink: false
};

PureEvaluationContainer.propTypes = {
  auth: AuthPropType,
  evaluation: EvaluationPropType,
  resourceSellerId: PropTypes.number.isRequired,
  showProductLink: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLegacyGrid: PropTypes.bool
};

const EvaluationContainer = compose(evaluationFragment)(PureEvaluationContainer);

export default EvaluationContainer;
