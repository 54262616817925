import React from 'react';
import { PropTypes } from 'prop-types';
import { get } from 'lodash';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import featureVariantsInfo from 'decorators/featureVariants';
import { memberDataPropTypes, featureVariantsPropTypes } from 'config/prop-definitions';
import withUserData from 'decorators/withUserData';
import Divider from 'components/Divider';
import LoadingAnimation from 'components/LoadingAnimation';
import EvaluationContainer from 'domains/Comments/containers/EvaluationContainer';
import LoadMoreButton from 'domains/Comments/components/LoadMoreButton';
import { userDataToAuth } from 'domains/Comments/helpers';
import { sellerEvaluationsQuery } from 'domains/Comments/queries';

import './StoreCommentsContainer.scss';

const displayName = 'StoreCommentsContainer';
const noCommentsMessage = 'Products in this store has not yet been rated';

export const PureStoreCommentsContainer = ({
  initialLoad,
  loading,
  sellerEvaluations,
  featureVariants,
  loadMoreEvaluations,
  userData
}) => {
  if (initialLoad) {
    return <LoadingAnimation isVisible centered />;
  }

  const evaluations = get(sellerEvaluations, 'evaluations', []);
  const hasNext = get(sellerEvaluations, 'hasNext', false);
  const auth = userDataToAuth(userData);
  const useResourceCatalog = featureVariants.rc_data_seller_store === 'on';

  return (
    <div className="StoreCommentsContainer">
      <div data-testid={`${displayName}__evaluations`}>
        <h3 className={`${displayName}__title`}>Reviews</h3>
        <div className="Comments--margin-top-md">
          {evaluations.length === 0 && (
            <div>
              <p data-testid={`${displayName}__noEvaluationsMessage`}>{noCommentsMessage}</p>
              <div className={`${displayName}__noCommentsPadding`} />
            </div>
          )}
          {evaluations.length !== 0 &&
            evaluations.map((evaluation) => (
              <article key={evaluation.id} className={`${displayName}__comment`}>
                <div className={`${displayName}__endDivider`}>
                  <Divider />
                </div>
                <EvaluationContainer
                  data-testid={`${displayName}__evaluationContainer`}
                  evaluationId={evaluation.id}
                  resourceId={evaluation.resourceId}
                  resourceSellerId={evaluation.sellerId}
                  auth={auth}
                  showProductLink
                  isLegacyGrid
                  useResourceCatalog={useResourceCatalog}
                />
              </article>
            ))}
        </div>
        {hasNext && (
          <div
            className={`${displayName}__loadMore`}
            data-testid={`${displayName}__evaluationsLoadMore`}
          >
            <Divider />
            <div className={`${displayName}__loadMore-button`}>
              <LoadMoreButton
                data-testid={`${displayName}__evaluationsLoadMoreButton`}
                label="Show more reviews"
                disabled={loading}
                onClick={loadMoreEvaluations}
              />
            </div>
          </div>
        )}
        <div className={`${displayName}__endDivider`}>
          <Divider />
        </div>
      </div>
    </div>
  );
};

PureStoreCommentsContainer.propTypes = {
  featureVariants: featureVariantsPropTypes,
  sellerEvaluations: PropTypes.shape({
    hasNext: PropTypes.bool,
    total: PropTypes.number,
    evaluations: PropTypes.arrayOf(PropTypes.object)
  }),
  loadMoreEvaluations: PropTypes.func,
  loading: PropTypes.bool,
  initialLoad: PropTypes.bool,
  userData: PropTypes.shape({ member: memberDataPropTypes }).isRequired
};

PureStoreCommentsContainer.defaultProps = {
  featureVariants: {
    rc_data_seller_store: 'off'
  },
  sellerEvaluations: {
    total: 0,
    hasNext: false,
    evaluations: []
  },
  loadMoreEvaluations: () => {},
  loading: true
};

export default compose(
  connect(({ config }) => ({ isLoggedIn: config.isLoggedIn })),
  withUserData(({ isLoggedIn }) => ({
    isLoggedIn
  })),
  featureVariantsInfo(['rc_data_seller_store']),
  sellerEvaluationsQuery
)(PureStoreCommentsContainer);
