import React from 'react';
import { PropTypes } from 'prop-types';
import EngagementSlider from '../EngagementSlider';
import { ENGAGEMENT_RATING } from '../constants';

import './EvaluationStudentEngagementSection.scss';

const renderEngagementDisplay = (studentEngagement) => {
  if (studentEngagement === 'none') {
    return <div className="EvaluationStudentEngagementSection__text">N/A</div>;
  }

  const { start, end } = ENGAGEMENT_RATING[studentEngagement];
  return <EngagementSlider start={start} end={end} min={1} max={6} />;
};

const EvaluationStudentEngagementSection = ({ studentEngagement }) => (
  <div className="EvaluationStudentEngagementSection">
    {renderEngagementDisplay(studentEngagement)}
  </div>
);

EvaluationStudentEngagementSection.propTypes = {
  studentEngagement: PropTypes.string.isRequired
};

export default EvaluationStudentEngagementSection;
