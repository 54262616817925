import React from 'react';
import { PropTypes } from 'prop-types';
import Button from 'components/Button';
import './ReplyButton.scss';

const ReplyButton = ({ onClick }) => (
  <div className="ReplyButton">
    <Button iconLeft="reply" label="Reply" variant="primary" onClick={onClick} size="small" />
  </div>
);

ReplyButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default ReplyButton;
