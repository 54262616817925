import { compose, graphql } from 'react-apollo';
import MarkEvaluationHelpful from 'tpt_modules/products/mutations/MarkEvaluationHelpful.graphql';
import UnmarkEvaluationHelpful from 'tpt_modules/products/mutations/UnmarkEvaluationHelpful.graphql';
import MarkAsHelpfulContainer from '.';

import './MarkEvaluationAsHelpfulContainer.scss';

export default compose(
  graphql(MarkEvaluationHelpful, {
    props: ({ mutate, ownProps: { evaluationId } }) => ({
      markAsHelpful: () => {
        if (global.heap) {
          global.heap.track('User marked evaluation as helpful', {
            evaluationId,
            category: 'Product Page'
          });
        }
        return mutate({ variables: { evaluationId } });
      }
    })
  }),
  graphql(UnmarkEvaluationHelpful, {
    props: ({ mutate, ownProps: { evaluationId } }) => ({
      unmarkAsHelpful: () => {
        if (global.heap) {
          global.heap.track('User unmarked evaluation as helpful', {
            evaluationId,
            category: 'Product Page'
          });
        }
        return mutate({ variables: { evaluationId } });
      }
    })
  })
)(MarkAsHelpfulContainer);
