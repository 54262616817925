/* eslint-disable camelcase */
import React from 'react';
import { PropTypes } from 'prop-types';
import { floor, isNaN } from 'lodash';
import Text from 'components/Text';
import Anchor from 'components/Anchor';
import CollapsibleContent from 'domains/Comments/components/CollapsibleContent';
import { EvaluationPropType, AuthPropType } from 'domains/Comments/proptypes';
import EvaluationStarRating from '../EvaluationStarRating';
import EvaluationAvatarByline from '../EvaluationAvatarByline';
import EvaluationStarRatingList from '../EvaluationStarRatingList';
import ReplyContainer from '../ReplyContainer';
import { LEGACY_EVALUATION_CATEGORIES, SHOW_LINE_HEIGHT } from '../constants';
import MarkEvaluationAsHelpfulContainer from '../../../containers/MarkAsHelpfulContainer/MarkEvaluationAsHelpfulContainer';

import './EvaluationLegacyDisplay.scss';

export const getRatings = (signals) => {
  const ratings = {};

  LEGACY_EVALUATION_CATEGORIES.forEach((category) => {
    ratings[category] = category in signals ? signals[category] : '0.00';
  });

  return ratings;
};

const EvaluationLegacyDisplay = ({
  auth,
  evaluation: {
    id,
    signals,
    updatedAt,
    user,
    helpfulCount,
    hasUserMarkedHelpful,
    sellerReply,
    resource
  },
  resourceSellerId,
  showProductLink,
  isMobile
}) => {
  const [showRatings, setShowRatings] = React.useState(false);

  const isReviewer = auth && user && auth.id === user.id;
  const ratingValue = signals.overall_qual_how_satisfied_stars;

  const formatRatingValue = () => {
    if (ratingValue === '0.00') {
      return 'N/A';
    }

    const rounded = floor(ratingValue, 1);

    return <Text color="textSecondary">{isNaN(rounded) ? '0' : rounded.toFixed(1)}</Text>;
  };

  return (
    <div className="EvaluationLegacyDisplay">
      {showProductLink && (
        <div className="EvaluationLegacyDisplay__link">
          <Anchor
            color="green"
            forceReload
            to={resource.url}
            data-testid="EvaluationLegacyDisplay--link"
          >
            {resource.name}
          </Anchor>
        </div>
      )}
      <EvaluationAvatarByline user={user} date={updatedAt} isMobile={isMobile} />
      {ratingValue && (
        <EvaluationStarRating rating={ratingValue}>{formatRatingValue()}</EvaluationStarRating>
      )}
      {signals.buyer_experience && (
        <div className="EvaluationLegacyDisplay__content">
          <CollapsibleContent
            collapsedContentHeight={SHOW_LINE_HEIGHT}
            hideContentLabel="Show less"
            showContentLabel="Show more"
            showIcon
          >
            {signals.buyer_experience}
          </CollapsibleContent>
        </div>
      )}
      <div className="EvaluationLegacyDisplay__ratings">
        <div className="EvaluationLegacyDisplay__ratings__text">
          <Text color="textSecondary" variant="bodySmall" component="span">
            This review was left prior to the new ratings system.{' '}
            {!showRatings && (
              <span
                role="button"
                tabIndex={0}
                className="EvaluationLegacyDisplay__ratings__text__link"
                onClick={() => setShowRatings(true)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    setShowRatings();
                  }
                }}
              >
                See rating breakdown.
              </span>
            )}
          </Text>
        </div>
        {showRatings && (
          <div className="EvaluationLegacyDisplay__ratings__list">
            {' '}
            <EvaluationStarRatingList ratings={getRatings(signals)} showDefaultText />
          </div>
        )}
      </div>
      <div className="EvaluationLegacyDisplay__actions">
        <MarkEvaluationAsHelpfulContainer
          helpfulCount={helpfulCount}
          hasCurrentUserMarkedHelpful={hasUserMarkedHelpful}
          isLoggedIn={!!(auth && auth.id)}
          evaluationId={id}
          canMarkAsHelpful={!isReviewer}
        />
        <ReplyContainer
          resourceSellerId={resourceSellerId}
          evaluationId={id}
          auth={auth}
          sellerReply={sellerReply}
        />
      </div>
    </div>
  );
};

EvaluationLegacyDisplay.propTypes = {
  auth: AuthPropType,
  evaluation: EvaluationPropType,
  resourceSellerId: PropTypes.number.isRequired,
  showProductLink: PropTypes.bool,
  isMobile: PropTypes.bool
};

EvaluationLegacyDisplay.defaultProps = {
  showProductLink: false
};

export default EvaluationLegacyDisplay;
