import React from 'react';
import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo';
import { PropTypes } from 'prop-types';
import FlexBox from 'components/FlexBox';
import Form from 'components/Form';
import EvaluationReplyMutation from 'tpt_modules/products/mutations/EvaluationReply.graphql';
import TextField from 'domains/Comments/components/TextField';
import SubmitButton from 'domains/Comments/components/SubmitButton';
import CancelButton from 'domains/Comments/components/CancelButton';
import { hasValidText } from 'domains/Comments/helpers';

import './ReplyForm.scss';

const REPLY_FORM_ID = 'ReplyTo';
const generateInitialFormValues = (sellerReply) => {
  const text = sellerReply ? sellerReply.text : '';
  return { text };
};
export const PureReplyForm = ({
  hideForm,
  evaluationId,
  formId,
  formValues: { text },
  replyMutation,
  tall
}) => {
  const canSubmit = hasValidText(text);

  const submitForm = () => {
    replyMutation(evaluationId, text);
    hideForm();
  };

  return (
    <Form data-testid="replyForm" formId={formId} initialValues={{ text }}>
      <TextField value={text} tall={tall} />
      <FlexBox className="ReplyForm__buttons" flexDirection="row-reverse" wrap>
        <SubmitButton label="Reply" onClick={submitForm} disabled={!canSubmit} />
        <CancelButton onClick={hideForm} />
      </FlexBox>
    </Form>
  );
};

PureReplyForm.defaultProps = {
  tall: false
};

PureReplyForm.propTypes = {
  hideForm: PropTypes.func.isRequired,
  evaluationId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  formValues: PropTypes.shape({
    text: PropTypes.string.isRequired
  }).isRequired,
  replyMutation: PropTypes.func.isRequired,
  tall: PropTypes.bool
};

export default compose(
  connect(({ form }, { evaluationId, sellerReply }) => {
    const formId = evaluationId ? `${REPLY_FORM_ID}_${evaluationId}` : REPLY_FORM_ID;
    const initFormValues = generateInitialFormValues(sellerReply);

    const { values = initFormValues } = form[formId] || {};
    return { formValues: values, formId };
  }),
  graphql(EvaluationReplyMutation, {
    props: ({ mutate }) => ({
      replyMutation: (evaluationId, text) => mutate({ variables: { evaluationId, text } })
    })
  })
)(PureReplyForm);
