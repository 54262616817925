import React from 'react';
import Range from 'rc-slider/lib/Range';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import 'rc-slider/assets/index.css';
import './GraphRange.scss';

const colorClass = {
  green: 'GraphRange__green',
  red: 'GraphRange__red',
  gray: 'GraphRange__gray',
  default: 'GraphRange__default'
};
const GraphRange = ({ min, max, start, end, color, labels }) => (
  <div className="GraphRange">
    <Range
      disabled
      min={min}
      max={max}
      dots
      defaultValue={[start, end]}
      marks={labels}
      className={classnames('GraphRange', colorClass[color])}
      ariaLabelGroupForHandles={['Engagement Slider Head', 'Engagement Slider Tail']}
    />
  </div>
);

GraphRange.defaultProps = {
  color: 'default'
};

GraphRange.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  color: PropTypes.string,
  labels: PropTypes.shape()
};

export default GraphRange;
