import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/LegacyButton';

const CancelButton = ({ onClick }) => <Button label="Cancel" skin="white link" onClick={onClick} />;

CancelButton.propTypes = {
  onClick: PropTypes.func
};

export default CancelButton;
