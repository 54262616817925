import React from 'react';
import { PropTypes } from 'prop-types';
import { SellerReplyPropType } from 'domains/Comments/proptypes';
import CollapsibleContent from 'domains/Comments/components/CollapsibleContent';
import EditReplyButton from '../EditReplyButton';
import EvaluationAvatarByline from '../EvaluationAvatarByline';

import './EvaluationSellerReply.scss';

const EvaluationSellerReply = ({ isResourceOwnedBySeller, sellerReply, editReply }) => (
  <div className="EvaluationSellerReply">
    <EvaluationAvatarByline
      user={sellerReply.user}
      date={sellerReply.updatedAt}
      isSellerReply
      showAvatar={false}
    />
    <CollapsibleContent
      // 24px per line and we want to limit to 3 lines
      collapsedContentHeight={24 * 3}
      hideContentLabel="Show less"
      showContentLabel="Show more"
      showIcon
    >
      {sellerReply.text}
    </CollapsibleContent>
    {isResourceOwnedBySeller && <EditReplyButton onClick={editReply} />}
  </div>
);

EvaluationSellerReply.propTypes = {
  sellerReply: SellerReplyPropType.isRequired,
  isResourceOwnedBySeller: PropTypes.bool.isRequired,
  editReply: PropTypes.func.isRequired
};

export default EvaluationSellerReply;
