import PropTypes from 'prop-types';
import React from 'react';
import withLocation from 'decorators/withLocation';
import Anchor from 'components/Anchor';

// Wraps children with an anchor link to the log-in page.
// Please use @authRequired to secure access to a page or particular component.
// @authRequired will redirect the page to the log-in page without user action.
const LoginRedirectLink = ({ className, children, redirect, location }) => {
  let pathname = '/';
  if (location && location.pathname) {
    ({ pathname } = location);
  } else if (global.location && global.location.pathname) {
    ({ pathname } = global.location);
  }

  return (
    <Anchor to={`/Login/?f=${redirect || pathname}`} className={className} forceReload>
      {children}
    </Anchor>
  );
};

LoginRedirectLink.propTypes = {
  className: PropTypes.string,
  redirect: PropTypes.string,
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

export default withLocation(LoginRedirectLink);
