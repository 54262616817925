/* eslint-disable @miovision/disallow-date/no-static-date */
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Anchor from 'components/Anchor';
import Avatar from 'components/Avatar';
import FormattedDate from 'react-intl/src/components/date';

import './EvaluationAvatarByline.scss';

const avatarSrc = (user) =>
  user && user.avatar && user.avatar.location ? user.avatar.location : null;

const displayName = (user) => (user && user.displayName ? user.displayName : 'Buyer');

const EvaluationAvatarByline = ({ user, date, isSellerReply, isMobile, showAvatar }) => {
  const isActiveSeller = user && user.isSeller && user.isActive;
  const formattedDate = date || Date.now();
  const displayNameClasses = classnames({
    'EvaluationAvatarByline__displayName--bold': isSellerReply
  });

  return (
    <div className="EvaluationAvatarByline">
      {showAvatar && (
        <Avatar src={avatarSrc(user)} size="mobile" alt={displayName(user)} isMobile={isMobile} />
      )}
      <div className="EvaluationAvatarByline__byline">
        {isActiveSeller ? (
          <span className="EvaluationAvatarByline__displayName">
            <Anchor color="black" forceReload to={user.storeUrl}>
              <span data-testid="displayName" className={displayNameClasses}>
                {user.displayName}
              </span>
            </Anchor>
            &nbsp;(TpT Seller)
          </span>
        ) : (
          <p className="EvaluationAvatarByline__displayName">{displayName(user)}</p>
        )}

        <p className="EvaluationAvatarByline__date">
          <FormattedDate value={formattedDate} year="numeric" month="long" day="numeric" />
        </p>
      </div>
    </div>
  );
};

EvaluationAvatarByline.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    storeUrl: PropTypes.string,
    isSeller: PropTypes.bool,
    isActive: PropTypes.bool,
    displayName: PropTypes.string,
    avatar: PropTypes.shape({
      location: PropTypes.string
    })
  }),
  date: PropTypes.string,
  isSellerReply: PropTypes.bool,
  isMobile: PropTypes.bool,
  showAvatar: PropTypes.bool
};

EvaluationAvatarByline.defaultProps = {
  isSellerReply: false,
  showAvatar: true
};

export default EvaluationAvatarByline;
