import React from 'react';
import { PropTypes } from 'prop-types';
import Button from 'components/Button';
import './EditReplyButton.scss';

const EditReplyButton = ({ onClick }) => (
  <div className="EditReplyButton">
    <Button
      className="EditReplyButton__button"
      iconLeft="pencil-square-o"
      label="Edit"
      variant="tertiary"
      onClick={onClick}
    />
  </div>
);

EditReplyButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default EditReplyButton;
