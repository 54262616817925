import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import Button from 'components/Button';

import './MarkAsHelpfulButton.scss';

const MarkAsHelpfulButton = ({
  helpfulCount,
  hasCurrentUserMarkedHelpful,
  onClick,
  canMarkAsHelpful
}) => {
  const iconName = hasCurrentUserMarkedHelpful && canMarkAsHelpful ? 'thumbs-up' : 'thumbs-o-up';
  const helpfulCountText = helpfulCount > 0 ? ` (${helpfulCount})` : '';
  const labelText = `Helpful${helpfulCountText}`;
  const displayName = classnames('MarkAsHelpfulButton', {
    'MarkAsHelpfulButton--reviewer': !canMarkAsHelpful
  });
  return (
    <Button
      className={displayName}
      iconLeft={iconName}
      label={labelText}
      variant="tertiary"
      onClick={onClick}
    />
  );
};

MarkAsHelpfulButton.propTypes = {
  helpfulCount: PropTypes.number.isRequired,
  hasCurrentUserMarkedHelpful: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  canMarkAsHelpful: PropTypes.bool
};

export default MarkAsHelpfulButton;
