export const EVALUATION_TYPES = {
  BETA_EVALUATION_TYPE: '1',
  LEGACY_EVALUATION_TYPE: '2',
  V1_EVALUATION_TYPE: '3'
};

export const COMMENT_MODES = {
  READ: 'read',
  EDIT_COMMENT: 'edit_comment',
  REPLY: 'reply'
};

export const COMMENT_DIRECTIONS = {
  LEFT: 'Left',
  RIGHT: 'Right'
};

export const COMMENT_TYPES = {
  QUESTIONS: 'QUESTIONS'
};

export const QUESTION_TYPES = {
  QUESTION: 'Question',
  ANSWER: 'Answer'
};

export const COMMENT_SORT = {
  HELPFUL_AND_NEWEST_FIRST: 'HELPFUL_AND_NEWEST_FIRST'
};

export const EVALUATIONS_AND_QUESTIONS_LIMIT = 5;

export const RATINGS_AND_REVIEWS_KEY = '#ratings-and-reviews';
export const PRODUCT_Q_AND_A_KEY = '#product-q-and-a';

// https://github.com/apollographql/apollo-client/blob/master/src/queries/networkStatus.ts
export const APOLLO_NETWORK_STATUS = {
  LOADING: 1, // initial load
  SET_VARIABLES: 2,
  FETCH_MORE: 3,
  REFETCH: 4,
  POLL: 6,
  READY: 7,
  ERROR: 8
};

export const RESOURCE_TYPES = {
  DIGITAL_PRODUCT: 'DIGITAL_PRODUCT',
  GIFT_CERTIFICATE: 'GIFT_CERTIFICATE',
  HARD_GOOD: 'HARD_GOOD',
  UNKNOWN: 'UNKNOWN',
  USED_GOOD: 'USED_GOOD',
  VIDEO: 'VIDEO',
  BUNDLE: 'BUNDLE',
  ONLINE_RESOURCE: 'ONLINE_RESOURCE'
};

export const ACTION_PLACEHOLDERS = {
  ASK_QUESTION: 'Post Question',
  ANSWER_QUESTION: 'Answer',
  RESPOND: 'Respond to Comment',
  REPLY: 'Reply'
};

export const ITEM_TYPE_CATEGORY_INDEX_MAP = {
  DIGITAL_PRODUCT: {
    Clarity: 1,
    Creativity: 2,
    Thoroughness: 3,
    Practicality: 4,
    Accuracy: 5,
    'Overall Quality': 6
  },
  GIFT_CERTIFICATE: {},
  HARD_GOOD: {
    Clarity: 7,
    Creativity: 8,
    Thoroughness: 9,
    Practicality: 10,
    Accuracy: 11,
    'Overall Quality': 12,
    'Shipping Efficiency': 13
  },
  USED_GOOD: {
    'Shipping Efficiency': 14,
    'Item as Advertised': 15
  },
  VIDEO: {
    Clarity: 16,
    Creativity: 17,
    Thoroughness: 18,
    Practicality: 19,
    Accuracy: 20,
    'Overall Quality': 21
  },
  BUNDLE: {
    Clarity: 22,
    Creativity: 23,
    Thoroughness: 24,
    Practicality: 25,
    Accuracy: 26,
    'Overall Quality': 27
  },
  ONLINE_RESOURCE: {
    Clarity: 28,
    Creativity: 29,
    Thoroughness: 30,
    Practicality: 31,
    Accuracy: 32,
    'Overall Quality': 33
  }
};

export const COMMENT_FORM_ID = 'CommentFrom';

export const EXCLUDED_REVIEW_BLACKOUT_RESOURCE_TYPES = [
  { sphinxId: '142', name: 'For Parents' },
  { sphinxId: '147', name: 'Posters' },
  { sphinxId: '18', name: 'Syllabi' },
  { sphinxId: '137', name: 'School Nurse Documents' },
  { sphinxId: '7', name: 'Teacher Manuals' },
  { sphinxId: '50', name: 'Word Walls' },
  { sphinxId: '52', name: 'Classroom Forms' },
  { sphinxId: '53', name: 'Bulletin Board Ideas' },
  { sphinxId: '48', name: 'Professional Documents' },
  { sphinxId: '171', name: 'Professional Development' },
  { sphinxId: '174', name: 'Fonts' },
  { sphinxId: '37', name: 'Grant Proposals' },
  { sphinxId: '39', name: 'Reflective Journals for Teachers' },
  { sphinxId: '165', name: 'For Principals & Administrators' },
  { sphinxId: '161', name: 'Rubrics' },
  { sphinxId: '162', name: 'By TpT Sellers for TpT Sellers' },
  { sphinxId: '152', name: 'Clip Art' },
  { sphinxId: '26', name: 'Elective Course Proposals' },
  { sphinxId: '113', name: 'Teaching Resources' },
  { sphinxId: '112', name: 'Pre-Made Bulletin Board' },
  { sphinxId: '107', name: 'Bulletin Boards' },
  { sphinxId: '108', name: 'Classroom Posters - Signs' },
  { sphinxId: '149', name: 'Classroom Decorations' }
];

export const CORE_API = ''; // it simply is teacherspayteachers.com

// TODO: i18n
export const DELETE_QUESTION_MESSAGE = 'Do you want to permanently delete this question?';
export const DELETE_ANSWER_MESSAGE = 'Do you want to permanently delete this answer?';
export const FLAG_MESSAGE = 'Are you sure you want to mark this comment as inappropriate?';

export const technicalIssuePhrases = [
  'blurry',
  'blurred',
  'fuzzy',
  'smudged',
  'smudges',
  'black boxes',
  'black lines',
  'grey lines',
  'grey boxes',
  "can't open",
  'could not open',
  "won't open",
  'will not open',
  'does not open',
  "doesn't open",
  'did not open',
  "didn't download",
  "doesn't download",
  'does not download',
  'did not download',
  'will not download',
  "won't download",
  'will not print',
  "can't print",
  'can not print',
  'printer',
  'can not save',
  'will not save',
  "can't save",
  "won't save",
  "didn't save",
  'did not save',
  'virus',
  'adobe',
  'mac',
  'faulty',
  'error',
  'empty',
  'password',
  'locked',
  'please advise',
  'unreadable',
  'unzip',
  'damaged',
  'corrupt',
  "won't print",
  "can't download",
  'please advice',
  'need password',
  'paid for it already',
  'printing the pages',
  'margins are messed up',
  'printing',
  'zip',
  'can not download',
  'able to download',
  'unable to download',
  'problem printing',
  'problems printing',
  'printing problem',
  'able to open',
  'can not open',
  'unable to open',
  'trying to download',
  'being rejected',
  'what do i do',
  'what should i do',
  'what can i do',
  'not able to download',
  'i need help',
  "can't get it to work",
  'will not work',
  'could not be downloaded',
  'would not open',
  "wouldn't open",
  'able to print',
  'i did not get',
  "didn't get",
  "what i'm doing wrong",
  'doing wrong',
  'formatting',
  "something's wrong",
  'something is wrong'
];
