import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import Row from 'components/ResponsiveGrid/ResponsiveRow';
import Col from 'components/ResponsiveGrid/ResponsiveCol';
import { StandardsHierarchyPropType } from 'domains/Comments/proptypes';
import EvaluationStudentEngagementSection from '../EvaluationStudentEngagementSection';
import EvaluationStudentGradesSection from '../EvaluationStudentGradesSection';
import EvaluationStandardsAlignmentSection from '../EvaluationStandardsAlignmentSection';
import {
  ALL_STUDENT_FACING_RESOURCE_OPTIONS,
  STUDENT_FACING_RESOURCE,
  STUDENT_FACING_RESOURCE_IN_CLASS_LEARNING,
  STUDENT_FACING_RESOURCE_PARENT_AT_HOME_LEARNING
} from '../constants';
import './EvaluationDataContainer.scss';

const createStudentEngagementContainer = (studentEngagement, howWasResourceUsed) => {
  if (
    !studentEngagement ||
    ![STUDENT_FACING_RESOURCE_IN_CLASS_LEARNING, STUDENT_FACING_RESOURCE].includes(
      howWasResourceUsed
    )
  ) {
    return null;
  }

  return (
    <Fragment>
      <div className="EvaluationDataContainer__title">Students were engaged</div>
      <EvaluationStudentEngagementSection studentEngagement={studentEngagement} />
    </Fragment>
  );
};

const createGradesUsedWithContainer = (gradesUsedWith, howWasResourceUsed, specialEdOptions) => {
  if (
    !gradesUsedWith ||
    gradesUsedWith.length === 0 ||
    !ALL_STUDENT_FACING_RESOURCE_OPTIONS.includes(howWasResourceUsed)
  ) {
    return null;
  }

  return (
    <Fragment>
      <div className="EvaluationDataContainer__title">Students used with</div>
      <EvaluationStudentGradesSection
        gradesUsedWith={gradesUsedWith}
        specialEdOptions={specialEdOptions}
      />
    </Fragment>
  );
};

const createStandardsAlignmentScoreContainer = (
  standardsAlignmentScore,
  standardsTaught,
  howWasResourceUsed
) => {
  if (
    !standardsAlignmentScore ||
    Object.keys(standardsAlignmentScore).length === 0 ||
    standardsTaught.length === 0 ||
    howWasResourceUsed === STUDENT_FACING_RESOURCE_PARENT_AT_HOME_LEARNING
  ) {
    return null;
  }

  return (
    <Fragment>
      <div className="EvaluationDataContainer__title">Alignment to standards</div>
      <EvaluationStandardsAlignmentSection
        standardsAlignmentScore={standardsAlignmentScore}
        standardsTaught={standardsTaught}
      />
    </Fragment>
  );
};

const EvaluationDataContainer = ({
  gradesUsedWith,
  standardsAlignmentScore,
  studentEngagement,
  standardsTaught,
  howWasResourceUsed,
  specialEdOptions,
  isLegacyGrid
}) => {
  const studentEngagementContainer = createStudentEngagementContainer(
    studentEngagement,
    howWasResourceUsed
  );
  const gradesUsedWithContainer = createGradesUsedWithContainer(
    gradesUsedWith,
    howWasResourceUsed,
    specialEdOptions
  );
  const standardsAlignmentContainer = createStandardsAlignmentScoreContainer(
    standardsAlignmentScore,
    standardsTaught,
    howWasResourceUsed
  );

  return (
    <div className="EvaluationDataContainer">
      <Row>
        {gradesUsedWithContainer && (
          <Col
            className="EvaluationDataContainer__gradesUsedWith"
            xs={12}
            md={isLegacyGrid ? 4 : 12}
            xl={4}
          >
            {gradesUsedWithContainer}
          </Col>
        )}
        {studentEngagementContainer && (
          <Col
            className="EvaluationDataContainer__studentEngagement"
            xs={12}
            sm={6}
            md={isLegacyGrid ? 4 : 6}
            xl={4}
          >
            {studentEngagementContainer}
          </Col>
        )}

        {standardsAlignmentContainer && (
          <Col
            className="EvaluationDataContainer__standardAlignment"
            xs={12}
            sm={6}
            md={isLegacyGrid ? 4 : 6}
            xl={4}
          >
            {standardsAlignmentContainer}
          </Col>
        )}
      </Row>
    </div>
  );
};

EvaluationDataContainer.propTypes = {
  gradesUsedWith: PropTypes.arrayOf(PropTypes.string),
  standardsAlignmentScore: PropTypes.object,
  studentEngagement: PropTypes.string,
  standardsTaught: PropTypes.arrayOf(StandardsHierarchyPropType),
  howWasResourceUsed: PropTypes.string,
  specialEdOptions: PropTypes.arrayOf(PropTypes.string),
  isLegacyGrid: PropTypes.bool
};

EvaluationDataContainer.defaultProps = {
  isLegacyGrid: false
};

export default EvaluationDataContainer;
