import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/LegacyButton';

const LoadMoreButton = ({ label, disabled, onClick }) => (
  <Button
    label={label}
    skin="primary outline"
    data-testid="CommentsLoadMoreButton"
    fullWidth
    onClick={onClick}
    disabled={disabled}
  />
);

LoadMoreButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

LoadMoreButton.defaultProps = {
  label: 'Load more'
};

export default LoadMoreButton;
