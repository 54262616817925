import React from 'react';
import { PropTypes } from 'prop-types';
import GraphRange from '../GraphRange';
import './EngagementSlider.scss';
import { ENGAGEMENT_RATING } from '../constants';

const EngagementSlider = ({ start, end, color, min, max }) => (
  <div className="EngagementSlider">
    <GraphRange
      min={min}
      max={max}
      start={start}
      end={end}
      labels={{
        1: ENGAGEMENT_RATING.disagree_strong.label,
        6: ENGAGEMENT_RATING.agree_strong.label
      }}
      color={color}
    />
  </div>
);

EngagementSlider.propTypes = {
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
};

export default EngagementSlider;
